import React, {useContext} from 'react';
import TopNavigation from "components/top-navigation/TopNavigation";
import {registerUser, registerUserAuth} from "data/userRequests";
import {useNavigate} from "react-router-dom";
import UserContext from "helpers/context/ContextApi";
import "./Register.css";

function Register() {
    const navigate = useNavigate();
    const contextValue = useContext(UserContext);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [registerFormData, setRegisterFormData] = React.useState({
        username: "",
        password: "",
        email: "",
        firstName: "",
        lastName: "",
        isAdmin: "0"
    });

    /*
    * Checks if user is logged in admin.
    */
    const isUserAdmin = ():boolean => {
        if (contextValue && contextValue.state && contextValue.state.user && contextValue.state.user.role_id) {
            if (contextValue.state.user.role_id == "1") return true;
        }
        return false
    }

    /*
    * Redirects to login page
    * */
    const redirectToLoginPage = () => {
        navigate('/login');
    }

    /*
    * Sends either register request by admin, which allows to create admins,
    * or send default register request by new not authenticated user.
    * */
    const registerRequestWrapper = async (e: React.FormEvent) => {
        isUserAdmin() ? sendRegisterAuthRequest(e) : sendRegisterRequest(e);
    };

    const sendRegisterAuthRequest = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await registerUserAuth(
                registerFormData.username,
                registerFormData.password,
                registerFormData.email,
                registerFormData.firstName,
                registerFormData.lastName,
                registerFormData.isAdmin
            );

            if (response?.status == "200") {
                redirectToLoginPage();
            }
        } catch (error) {
            console.error("unsuccessful register with authentication request");
        }
        setLoading(false);
    }

    /*
    * Sends register request
    * */
    const sendRegisterRequest = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await registerUser(
                registerFormData.username,
                registerFormData.password,
                registerFormData.email,
                registerFormData.firstName,
                registerFormData.lastName,
                registerFormData.isAdmin
            );

            if (response?.status == "200") {
                redirectToLoginPage();
            }
        } catch (error) {
            console.error("unsuccessful register request");
        }
        setLoading(false);
    }

    /*
    * Updates state of register credentials on user input change:
    * */
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setRegisterFormData({...registerFormData, [name]: value });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name} = e.target;
        if (e.target.checked == true) {
            setRegisterFormData({...registerFormData, [name]: "1" });
        } else {
            setRegisterFormData({...registerFormData, [name]: "0" });
        }
    }

    // csont handleToggleInputChange

    return (
        <div className={"pageWrapper"}>
            <TopNavigation />

            <div className={"pageContent"}>
                <form className={"registerPageContent"} onSubmit={registerRequestWrapper}>
                    <span className={"header"}>Register new user account:</span>
                    <div className={"registerInputWrapper"}>
                        <label className={"captionBox caption"} htmlFor={"username"}>Username:</label>
                        <input
                            className={"buttonStyle"}
                            type={"text"}
                            id={"username"}
                            name={"username"}
                            value={registerFormData.username}
                            onChange={handleInputChange}
                            data-testid={"usernameRegTestId"}
                        />
                    </div>
                    <div className={"registerInputWrapper"}>
                        <label className={"captionBox caption"} htmlFor={"email"}>Email:</label>
                        <input
                            className={"buttonStyle"}
                            type={"email"}
                            id={"email"}
                            name={"email"}
                            value={registerFormData.email}
                            onChange={handleInputChange}
                            data-testid={"emailRegTestId"}
                        />
                    </div>
                    <div className={"registerInputWrapper"}>
                        <label className={"captionBox caption"} htmlFor={"password"}>Password:</label>
                        <input
                            className={"buttonStyle"}
                            type={"password"}
                            id={"password"}
                            name={"password"}
                            value={registerFormData.password}
                            onChange={handleInputChange}
                            data-testid={"pwdRegTestId"}
                        />
                    </div>
                    <div className={"registerInputWrapper"}>
                        <label className={"captionBox caption"} htmlFor={"firstName"}>First Name:</label>
                        <input
                            className={"buttonStyle"}
                            type={"text"}
                            id={"firstName"}
                            name={"firstName"}
                            value={registerFormData.firstName}
                            onChange={handleInputChange}
                            data-testid={"firstNameRegTestId"}
                        />
                    </div>
                    <div className={"registerInputWrapper"}>
                        <label className={"captionBox caption"} htmlFor={"lastName"}>Last Name:</label>
                        <input
                            className={"buttonStyle"}
                            type={"text"}
                            id={"lastName"}
                            name={"lastName"}
                            value={registerFormData.lastName}
                            onChange={handleInputChange}
                            data-testid={"LastNameRegTestId"}
                        />
                    </div>
                    {isUserAdmin() &&
                        <div className={"registerInputWrapper"}>
                            <label className={"captionBox caption"} htmlFor={"isAdmin"}>Create Admin:</label>
                            <input
                                className={"buttonStyle checkBoxStyle"}
                                type={"checkbox"}
                                id={"isAdmin"}
                                name={"isAdmin"}
                                value={registerFormData.isAdmin}
                                onChange={handleCheckboxChange}
                                data-testid={"adminRegTestId"}
                            />
                        </div>
                    }
                    <div className={"registerInputWrapper"}>
                        <button className={"buttonStyle"} type={"submit"} data-testid={"submitRegTestId"}>
                            <span>Register</span>
                        </button>
                    </div>
                    {loading && <div>
                        <span>Registering user....</span>
                    </div>}
                </form>
            </div>
        </div>
    )
}

export default Register;
