import React from 'react';
import TopNavigation from "components/top-navigation/TopNavigation";
import "./Login.css"
import {useNavigate} from "react-router-dom";
import {loginUser} from "data/userRequests";
import {useUser} from "helpers/context/ContextApi";

function Login() {
    const navigate = useNavigate();
    // @ts-ignore
    const { state, dispatch } = useUser();

    const [loginFormData, setLoginFormData] = React.useState({username: "", password: ""})
    const [loading, setLoading] = React.useState<boolean>(false);

    /*
    * Updates state of login credentials on user input change:
    * */
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setLoginFormData({...loginFormData, [name]: value });
    }

    /*
    * Redirects to page with List of Binary Trees
    * */
    const redirectToListPage = () => {
        navigate('/list');
    }

    /*
    * Sends login request
    * */
    const sendLoginRequest = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await loginUser(loginFormData.username, loginFormData.password);

            if (response?.status == "200") {
                if(response.role_id) {
                    dispatch({
                        type: "LOGIN",
                        payload: {
                            role_id: response.role_id,
                            user_id: response.user_id,
                            username: response.username,
                            profile_picture_url: response.profile_picture_url
                        }
                    });
                }
                redirectToListPage();
            }
        } catch (error) {
            console.error("unsuccessful login request");
        }
        setLoading(false);
    }

    return(
        <div className={"pageWrapper"}>
            <TopNavigation />
            <div className={"pageContent"}>
                <form onSubmit={sendLoginRequest}>
                    <div className={"inputWrapper"}>
                        <label className={"captionBox caption"} htmlFor={"username"}>Username:</label>
                        <input
                            className={"buttonStyle"}
                            type={"text"}
                            id={"username"}
                            name={"username"}
                            value={loginFormData.username}
                            onChange={handleInputChange}
                            data-testid={"usernameTestId"}
                        />
                    </div>
                    <div className={"inputWrapper"}>
                        <label className={"captionBox caption"} htmlFor={"password"}>Password:</label>
                        <input
                            className={"buttonStyle"}
                            type={"password"}
                            id={"password"}
                            name={"password"}
                            value={loginFormData.password}
                            onChange={handleInputChange}
                            data-testid={"passwordTestId"}
                        />
                    </div>
                    <div className={"inputWrapper"}>
                        <button className={"buttonStyle"} type={"submit"} name={"submitButton"} data-testid={"loginButtonTestId"}>
                            <span>Login</span>
                        </button>
                    </div>
                    {loading && <div>
                        <span>Logging in....</span>
                    </div>}
                </form>
            </div>
        </div>
    )
}

export default Login;
