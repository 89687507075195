import React from 'react';
import TopNavigation from "components/top-navigation/TopNavigation";
import {getAllBinaryTrees, getAllTreesByUserId} from "data/binaryTreesRquests";
import {BinaryTreeInterface} from "data/interfaces";
import "./ListOfTrees.css";
import {useNavigate} from "react-router-dom";
import {useUser} from "helpers/context/ContextApi";

function ListOfTrees() {
    // @ts-ignore , context api lint issue
    const { state  } = useUser();
    const navigate = useNavigate();

    const [currentUserId, setCurrentUserId] = React.useState<string>("");
    const [binaryTrees, setBinaryTrees] = React.useState<BinaryTreeInterface[] | null>(null);
    const [rerender, setRerender] = React.useState<boolean>(false);

    // triggered on component initialization:
    React.useEffect(() => {
        // set user id:
        setCurrentUserId(state.user?.user_id,);

        // fetch list of binary trees"
        fetchBinaryTreesData();
    }, [rerender]);

    const fetchBinaryTreesData = async () => {
        const fetchedBinaryTrees = await getAllBinaryTrees();
        setBinaryTrees(fetchedBinaryTrees);
    }

    const handleTreesFilterCheckbox = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked == true) {
            fetchTreesCreatedByUser();
        } else {
            fetchBinaryTreesData();
        }
    }
    const fetchTreesCreatedByUser = async () => {
        const userId: number = state.user.user_id;
        if (userId > 0) {
            const fetchedUserTrees = await getAllTreesByUserId(userId);
            setBinaryTrees(fetchedUserTrees);
        } else {
            console.error("User id missing, cant fetch user trees...");
        }
    }

    const selectBinaryTree = (treeId: number, treeTitle: string) => {
        navigate('/tree', {state: {tree_id: treeId, tree_title: treeTitle}});
    }

    const editTree = (tree: BinaryTreeInterface) => {
        navigate('/edit_tree', {
            state: {
                tree_id: tree.tree_id,
                title: tree.title,
                user_id: tree.user_Id,
            }
        });
    }

    return(
        <div className={"pageWrapper"}>
            <TopNavigation />
            <div className={"pageContent"}>
                <span className={"header"}>List of Binary Trees:</span>
                <div className={"userTreesFilterRow"}>
                    <span>Show only trees created by current user</span>
                    <input className={"checkBoxStyle"} type={"checkbox"} onChange={handleTreesFilterCheckbox}/>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th className={"idCol col"}>Tree id:</th>
                            <th className={"titleCol col"}>Title:</th>
                            <th className={"editCol col"}>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                    {binaryTrees && binaryTrees.map((tree: BinaryTreeInterface, id: number) => (
                        <tr key={id}>
                            <td className={"col"} onClick={()=> selectBinaryTree(tree.tree_id, tree.title)}>
                                <div>{tree.tree_id}</div>
                            </td>
                            <td className={"col"} onClick={()=> selectBinaryTree(tree.tree_id, tree.title)}>
                                <div>{tree.title}</div>
                            </td>
                            <td className={"col"}>
                                {Number(currentUserId) == tree.user_Id && <div>
                                    <button onClick={()=>editTree(tree)}>Edit</button>
                                </div>}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ListOfTrees;
