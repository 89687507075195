import React from 'react';
import {editSingleNodeRequest} from "data/binaryTreesRquests";

interface FormDataProps {
    tree_id: string,
    caption: string,
    node_id: string,
};

interface SidebarEditNodeProps {
    isOpen: boolean,
    rerenderBinaryTreeCallback: React.Dispatch<React.SetStateAction<boolean>>,
    toggleSidebarOpenCallback: React.Dispatch<React.SetStateAction<boolean>>,
    rerenderCallback: React.Dispatch<React.SetStateAction<boolean>> | null,
    treeId: string,
    nodePosition: string
    nodeCaption: string
    parentId: string
    nodeId: string,
}

function SidebarEditNode({isOpen, rerenderBinaryTreeCallback, toggleSidebarOpenCallback, rerenderCallback, treeId, nodeCaption, nodeId }: SidebarEditNodeProps) {
    const [formData, setFormData] = React.useState<FormDataProps>( {tree_id: treeId, caption: nodeCaption, node_id: nodeId})

    // rerender component and set newly provided values to form.
    React.useEffect(()=> {
        setFormData({tree_id: treeId, caption: nodeCaption, node_id: nodeId});
    }, [treeId, nodeId, nodeCaption]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const response = await editSingleNodeRequest(formData.tree_id, formData.caption, formData.node_id);

        if (response?.updated_rows.length > 0 && rerenderCallback) {
            // rerender tree component
            rerenderCallback(prevState => !prevState);
            // rerender node component
            rerenderBinaryTreeCallback(prevState => !prevState);
            // hide edit node sidebar:
            toggleSidebarOpenCallback(false)
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const closeSidebar = () => {
        toggleSidebarOpenCallback(false)
    }

    return (
        <div className={(isOpen) ? "sidebarOpen sidebarContainer" : "sidebarContainer"}>
            <div className={"closeSidebarRow"}>
                <div className={"closeButton"} onClick={()=> {closeSidebar()}}><span className={"formCaption"}>{"X"}</span></div>
            </div>
            <form className={"addNodeContainer"} onSubmit={handleSubmit}>
                <div className={"addNodeFormRow"}>
                    <label className={"formCaption"}>Edit Node Caption:</label>
                    <textarea
                        className={"textAreaInputStyle"}
                        value={formData.caption}
                        onChange={handleChange}
                        name={"caption"}
                    />
                </div>
                <div className={"addNodeFormRow"}>
                    <div className={"buttonStyle"}>
                        <button className={"buttonStyle"} type={"submit"}>CONFIRM</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SidebarEditNode;
