import React, {ReactNode} from 'react';
import {BinaryTreeNodeDTOInterface} from "data/interfaces";
import './TreeNode.css';
import Arrow from "./arrow/Arrow";
import {deleteSingleNodeRequest} from "data/binaryTreesRquests";

interface TreeNodeProps {
    node: BinaryTreeNodeDTOInterface;
    renderNodeRecursivelyCallback: (node: BinaryTreeNodeDTOInterface) => ReactNode,
    edit: boolean,
    rerenderFromTree: boolean
    openSidebarCallback: React.Dispatch<React.SetStateAction<boolean>>;
    setNodeForSidebarCallback: React.Dispatch<React.SetStateAction<BinaryTreeNodeDTOInterface>>,
    openCreateNodeSidebarCallback?: React.Dispatch<React.SetStateAction<boolean>> | null,
    setNextNodePosCallback?: React.Dispatch<React.SetStateAction<string>> | null,
    setParentNodeIdCallback?: React.Dispatch<React.SetStateAction<string>> | null,
    setCurrentNodeLevelCallback?: React.Dispatch<React.SetStateAction<string>> | null;
    rerenderCallback?: React.Dispatch<React.SetStateAction<boolean>> | null,
}


/*
* Recursive class that calls itself again to create new TreeNode component based on the provided node.
* create node params:
* tree_id: string, parent_id: string, caption: string, position: string
* */
function TreeNode({
                      node,
                      renderNodeRecursivelyCallback,
                      edit,
                      rerenderFromTree,
                      setNodeForSidebarCallback,
                      openSidebarCallback,
                      openCreateNodeSidebarCallback,
                      setNextNodePosCallback,
                      setParentNodeIdCallback,
                      setCurrentNodeLevelCallback,
                      rerenderCallback
}: TreeNodeProps) {
    const [rerenderHelper, setRerenderHelper] = React.useState(0);

    const forceRerenderHelper = (): void => {
        setRerenderHelper(rerenderHelper + 1);
    }

    React.useEffect(()=>{
        forceRerenderHelper();
    }, [rerenderFromTree]);

    const openSidebarWrapper = () => {
        setNodeForSidebarCallback(node);
        openSidebarCallback(true);
    };

    const getSlidingClass = (): string => {
        if (node.nodeLevel === 0 || node.nodeLevel == 1) {
            if (node.nodePosition === "left") {
                return 'slide-left';
            }
            if (node.nodePosition === "right") {
                return 'slide-right';
            }
        }
        return '';
    }

    // used to open sidebar that is in EditTree page. Different from Sidebar to edit node. It allows to create new node.
    const openCreateNewNodeSidebarWrapper = (position: string) => {
        if (openCreateNodeSidebarCallback &&
            setNextNodePosCallback &&
            setParentNodeIdCallback &&
            setCurrentNodeLevelCallback
        ) {
            setNextNodePosCallback(position);
            setParentNodeIdCallback(node.current_node.id.toString());
            setCurrentNodeLevelCallback(node.current_node.node_level?.toString() || "0");
            openCreateNodeSidebarCallback(true);
        }
    };

    const getSingleChildNodeClass = (): string => {
        if (node.right_node === null) {
            const childNodeLevel = node.left_node?.nodeLevel;
            switch (childNodeLevel) {
                case 1:
                    return "onlyLeftNodeTopLevel";
                    break;
                case 2:
                    return "onlyLeftNodeSecondLevel";
                    break;
                case 3:
                    return "onlyLeftNodeThirdLevel";
                    break;
                default:
                    return "";
                    break;
            }
        }
        return "";
    }

    /*
    * get class width for empty node
    * */
    const getTreeLevelClassForEmptyNode = (nodeLevel: number | undefined): string => {
        if (nodeLevel) {
            switch (nodeLevel) {
                case 0:
                    return "topLevel";
                    break;
                case 1:
                    return "secondLevel";
                    break;
                case 2:
                    return "thirdLevel";
                    break;
                default:
                    return "";
            }
        }
        return "";
    }

    /*
    * Delete node request:
    * */
    const deleteNodeRequest = async () => {
        const response = await deleteSingleNodeRequest(String(node.node_id));
        if (response.updated_rows && response.updated_rows > 0) {
            // rerender after delete..
            if (rerenderCallback) {
                rerenderCallback(prevState => !prevState);
            }
        }
    }

    return (<>
        <div className={`mainNodeWrapper ${getSlidingClass()}`}>
            {edit == true &&
                <div className={"twoButtonsContainer"}>
                    <div className={"pairedButtonContainer"}>
                        <button className={"pairedButton buttonStyle"} onClick={() => openSidebarWrapper()}>Edit</button>
                    </div>
                    {node.current_node.left_child_id == null && node.current_node.right_child_id == null &&
                        <div className={"pairedButtonContainer"}>
                            <button className={"pairedButton buttonStyle"} onClick={() => deleteNodeRequest()}>{"Delete"}</button>
                        </div>
                    }
                </div>
            }
            {/* Commented out NODES below are used strictly for debugging. displays information about each node.*/}
            {/*<div className={"nodeCaption"}>*/}
            {/*    <span>id: {node.node_id} </span>*/}
            {/*    <span>level: {node.nodeLevel} </span>*/}
            {/*</div>*/}
            {/*<div className={"nodeCaption"}>*/}
            {/*    <span>{node.nodePosition} </span>*/}
            {/*    <span>| path: {node.path} </span>*/}
            {/*</div>*/}
            <div className={"nodeCaption"}>
                <span className={"xlCaption"}>{node.current_node.caption} </span>
            </div>
            {edit == true && (
                <div className={"nodeCaption"}>
                    {node.left_node === null && node.right_node === null &&
                        <span>Add below:</span>
                    }
                    <div className={"twoButtonsContainer"}>
                        <div className={"pairedButtonContainer"}>
                            {node.left_node === null &&
                                (<button className={"pairedButton buttonStyle"} onClick={() => openCreateNewNodeSidebarWrapper("L")}>{"Left"}</button>)
                            }
                        </div>
                        <div className={"pairedButtonContainer"}>
                            {node.right_node === null &&
                                (<button className={"pairedButton buttonStyle"} onClick={() => openCreateNewNodeSidebarWrapper("R")}>{"Right"}</button>)
                            }
                        </div>
                    </div>
                </div>
            )}
        </div>
        <div className={`childrenNodesWrapper`}>
            {node.left_node !== null ?
                <div className={`childNodeWrapper ${getSingleChildNodeClass()} ${getSlidingClass()}`}>
                    <Arrow direction={"left"}/>
                    {renderNodeRecursivelyCallback(node.left_node)}
                </div>
                :
                <div className={`childNodeWrapper ${getSlidingClass()} ${getTreeLevelClassForEmptyNode(node.nodeLevel)}`}>
                </div>
            }
            {node.right_node !== null ?
                <div className={`childNodeWrapper ${getSlidingClass()}`}>
                    {node.left_node == null ?
                        <Arrow direction={"right"} isSingleRightArrow={true}/> :
                        <Arrow direction={"right"}/>
                    }
                    {renderNodeRecursivelyCallback(node.right_node)}
                </div>
                :
                <div className={`childNodeWrapper ${getSlidingClass()} ${getTreeLevelClassForEmptyNode(node.nodeLevel)}`}>
                </div>
            }
        </div>
    </>);
}

export default TreeNode;
