import React from 'react';
import './SidebarAddNode.css';
import {createNewNodeRequest} from "data/binaryTreesRquests";

interface FormDataProps {
    tree_id: string,
    parent_id: string,
    caption: string,
    position: string
};

interface SidebarAddNodeProps {
    isOpen: boolean,
    toggleSidebarOpenCallback: React.Dispatch<React.SetStateAction<boolean>>,
    rerenderCallback: React.Dispatch<React.SetStateAction<boolean>>,
    treeId: string,
    nodePosition: string
    rerenderForm: boolean
    parentId: string
    currentNodeLevel: string
}

function SidebarAddNode({
                            isOpen,
                            toggleSidebarOpenCallback,
                            rerenderCallback,
                            treeId,
                            nodePosition,
                            rerenderForm,
                            parentId,
                            currentNodeLevel
}: SidebarAddNodeProps) {
    const [formData, setFormData] = React.useState<FormDataProps>( {tree_id: "", parent_id: "-1", caption: "", position: ""})

    React.useEffect(() => {
        setFormData({
            ...formData,
            parent_id: parentId,
            tree_id: treeId,
            position: nodePosition
        })
    }, [rerenderForm]);

    React.useEffect(()=> {
        setFormData({
            ...formData,
            tree_id: treeId,
            position: nodePosition
        })
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        // increase the current node level by 1, and then transfer it back to string for api request.
        const newNodeLevel = Number(currentNodeLevel) + 1;
        const newNodeLevelStr = String(newNodeLevel);

        const response = await createNewNodeRequest(formData.tree_id, formData.parent_id, formData.caption, formData.position, newNodeLevelStr);
        if (response !== null) {
            rerenderCallback(prevState => !prevState);
            toggleSidebarOpenCallback(false)
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const closeSidebar = () => {
        toggleSidebarOpenCallback(false)
        setFormData({
            ...formData,
            caption: "",
        })

    }

    return(
        <div className={(isOpen) ? "sidebarOpen sidebarContainer" : "sidebarContainer"}>
            <div className={"closeSidebarRow"}>
                <div className={"closeButton"} onClick={()=> {closeSidebar()}}><span className={"formCaption"}>{"X"}</span></div>
            </div>
            <form className={"addNodeContainer"} onSubmit={handleSubmit}>
                <div className={"addNodeFormRow"}>
                    <label className={"formCaption"}>Node Caption:</label>
                    <textarea
                        className={"textAreaInputStyle"}
                        value={formData.caption}
                        onChange={handleChange}
                        name={"caption"}
                        data-testid={"sidebarNodeCaptionInputTestId"}
                    />
                </div>
                <div className={"addNodeFormRow"}>
                    <div className={"buttonStyle"}>
                        <button
                            className={"buttonStyle"}
                            type={"submit"}
                            data-testid={"sidebarNodeSubmitButtonTestId"}
                        >CONFIRM</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SidebarAddNode;
