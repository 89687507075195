import React from 'react';
import TopNavigation from "components/top-navigation/TopNavigation";
import {useLocation} from "react-router-dom";
import {getBinaryTree} from "data/binaryTreesRquests";
import {BinaryTreeNodeDTOInterface} from "data/interfaces";
import BinaryTree from "./binary-tree/BinaryTree";
import "./SingleTree.css";

function SingleTree() {
    const [treeId, setTreeId] = React.useState<number>(-1);
    const [treeTitle, setTreeTitle] = React.useState<string>("")
    const [rerender, setRerender] = React.useState<boolean>(false);
    const [tree, setTree] = React.useState<BinaryTreeNodeDTOInterface | null>(null);

    const newId = useLocation().state.tree_id;
    const newTitle = useLocation().state.tree_title;

    /*
    * This function is called initially on component render,
    * sets the id that is passed down from relocate,
    * will be called again when rerender variable changes its value.
    * */
    React.useEffect(() => {
        setTreeId(newId);
        setTreeTitle(newTitle);
    }, [rerender]);

    /*
    * This function is called every time when treeId variable is changed:
    * */
    React.useEffect(() => {
        if (treeId != -1) {
            fetchBinaryTreeData()
        }
    }, [treeId])

    const fetchBinaryTreeData = async () => {
        const tree = await getBinaryTree(treeId);
        setTree(tree);
    }

    return(
        <div className={"pageWrapper"}>
            <TopNavigation />
            <div className={"pageContent"}>
                <div className={"headerCaptionRow"}>
                    <div className={"headerCaptionWrapper"}>
                        <span className={""}>{`(id: ${treeId})`}</span>
                    </div>
                    <div className={"headerCaptionWrapper"}>
                        <span>{`Title: ${treeTitle}`}</span>
                    </div>
                </div>
                {tree !== null && (
                    <BinaryTree
                        rootNode={tree}
                        edit={false}
                        rerenderCallback={null}
                        rerender={rerender}
                    />
                )}
            </div>
        </div>
    )
}

export default SingleTree;
