import { endPointUrlLocal } from "./staticData";
import { getToken } from "../helpers/Helpers";

const endPointUrl = endPointUrlLocal;

/*
* GET request, gets list of binary trees with limited informaiton about each
* */
export async function getAllBinaryTrees() {
    const apiUrl = endPointUrl + '/api/public/list-of-trees/all'
    const token = getToken();

    if (token.length > 0) {
        try {
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "AUTHORIZATION": token
                },
            });

            if(!response.ok) {
                throw new Error("Request get /api/public/list-of-trees/all failed with status: " + response.status);
            }

            const binaryTrees = await response.json();
            return binaryTrees;
        } catch (error) {
            console.error("Error attempting to perform request GET: /api/public/list-of-trees/all ");
        }
    }
}

/*
* GET request, gets list of all binary trees created by given user_id:
* */
export async function getAllTreesByUserId(user_id: number) {
    const apiUrl = endPointUrl + '/api/public/list-of-trees/all-user-trees/' + user_id;
    const token = getToken();

    if (token.length > 0) {
        try {
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "AUTHORIZATION": token
                },
            });

            if(!response.ok) {
                throw new Error("Request get /api/public/list-of-trees/all-user-trees/ failed with status: " + response.status);
            }

            const binaryTrees = await response.json();
            return binaryTrees;
        } catch (error) {
            console.error("Error attempting to perform request GET: /api/public/list-of-trees/all-user-trees/ ");
        }
    }
}

/*
* GET request, gets full binary tree with information about all its nodes.
* */
export async function getBinaryTree(tree_id: number) {
    const apiUrl = endPointUrl + '/api/public/list-of-trees/single-tree/' + tree_id;
    const token = getToken();

    if (token.length > 0) {
        try {
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "AUTHORIZATION": token
                },
            });

            if(!response.ok) {
                throw new Error("Request get /api/public/list-of-trees/single-tree/{tree_id} failed with status: " + response.status);
            }

            const binaryTree = await response.json();
            return binaryTree;
        } catch(error) {
            console.error("Error attempting to perform request GET: /api/public/list-of-trees/single-tree/{tree_id} ");
        }
    }
}

/*
* POST request, creates single new Binary Tree (without its nodes)
* */
export async function createNewBinaryTreeRequest(title: string) {
    const apiUrl = endPointUrl + '/api/public/list-of-trees/create-tree';
    const token = getToken();

    if (token.length > 0) {
        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "AUTHORIZATION": token
                },
                credentials: "include",
                body: JSON.stringify({
                    title
                }),
            })

            if(!response.ok) {
                throw new Error("Request get /api/public/list-of-trees/create-tree failed" + response);
            }

            const createdBinaryTree = await response.json();
            return createdBinaryTree;
        } catch(error) {
            console.error("Error attempting to perform request POST: /api/public/list-of-trees/create-tree ");
        }
    }
}

/*
* POST requests, creates a single new Tree Node:
* */
export async function createNewNodeRequest(tree_id: string, parent_id: string, caption: string, position: string, node_level: string) {
    const apiUrl = endPointUrl + '/api/public/list-of-trees/create-node';
    const token = getToken();

    if (token.length > 0) {
        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "AUTHORIZATION": token
                },
                credentials: "include",
                body: JSON.stringify({
                    tree_id,
                    parent_id,
                    caption,
                    position,
                    node_level
                }),
            })

            if(!response.ok) {
                throw new Error("Request get /api/public/list-of-trees/create-node failed" + response);
            }

            const createdNode = await response.json();
            return createdNode;
        } catch(error) {
            console.error("Error attempting to perform request POST: /api/public/list-of-trees/create-node ");
        }
    }
}

/*
* PATCH requests, edits a single Tree Node:
* */
export async function editSingleNodeRequest(tree_id: string, caption: string, node_id: string) {
    const apiUrl = endPointUrl + '/api/public/list-of-trees/edit-single-node';
    const token = getToken();

    if (token.length > 0) {
        try {
            const response = await fetch(apiUrl, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "AUTHORIZATION": token
                },
                credentials: "include",
                body: JSON.stringify({
                    tree_id,
                    caption,
                    node_id
                }),
            })

            if(!response.ok) {
                throw new Error("Request get /api/public/list-of-trees/edit-single-node failed" + response);
            }

            const editedNode = await response.json();
            return editedNode;
        } catch(error) {
            console.error("Error attempting to perform request POST: /api/public/list-of-trees/edit-single-node ");
        }
    }
}

/*
* DELETE requests, deletes a single Tree Node:
* */
export async function deleteSingleNodeRequest(node_id: string) {
    const apiUrl = endPointUrl + '/api/public/list-of-trees/delete/' + node_id;
    const token = getToken();

    if (token.length > 0) {
        try {
            const response = await fetch(apiUrl, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "AUTHORIZATION": token
                },
                credentials: "include",
            })

            if(!response.ok) {
                throw new Error("Request get /api/public/list-of-trees/delete/ failed" + response);
            }

            const deletedNodeResponse = await response.json();
            return deletedNodeResponse;
        } catch(error) {
            console.error("Error attempting to perform request POST: /api/public/list-of-trees/delete/ ");
        }
    }
}
