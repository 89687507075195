import React from 'react';
import TopNavigation from "components/top-navigation/TopNavigation";
import {createNewBinaryTreeRequest, getBinaryTree} from "data/binaryTreesRquests";
import './CreateTree.css';
import {CreateTreeResponse} from "data/interfaces";
import {useNavigate} from "react-router-dom";

/*
* Component includes form to create new tree in the database with given title,
* Created tree id will be returned to frontend.
* With newly created tree_id we can create a first node and then allow user to add additional nodes as needed.
* */
function CreateTree() {
    const navigate = useNavigate();
    const [title, setTitle] = React.useState<string>("");
    const [titleSent, setTitleSent] = React.useState<boolean>(false);
    const [mainTreeObject, setMainTreeObject] = React.useState<CreateTreeResponse>({tree_id: "", title: "", tree_depth: "", user_id: ""})

    /*
    * Called everytime title initial Binary Tree object was created
    * navigates to EditTree page where newly created Binary Tree can have its nodes added and edited.
    * */
    React.useEffect(() => {
        // setRerenderForm(prevState => !prevState);
        if (titleSent == true) {
            navigate('/edit_tree', {
                state: {
                    tree_id: mainTreeObject.tree_id,
                    title: mainTreeObject.title,
                    tree_depth: mainTreeObject.tree_depth,
                    user_id: mainTreeObject.user_id
                }
            });
        }
    }, [titleSent]);

    const sendCreateNewTreeRequest = async (e: React.FormEvent) => {
        e.preventDefault();
        const newTreeFromResponse: CreateTreeResponse = await createNewBinaryTreeRequest(title);

        if (newTreeFromResponse?.tree_id.length > 0) {
            setMainTreeObject({
                ...mainTreeObject,
                tree_id: newTreeFromResponse.tree_id,
                title: newTreeFromResponse.title,
                tree_depth: newTreeFromResponse.tree_depth,
                user_id: newTreeFromResponse.user_id
            });
            setTitleSent(true);
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setTitle(value);
    }

    return (
        <div className={"pageWrapper"}>
            <TopNavigation />
            <div className={"pageContent"}>
                <span className={"header"} >Create new Binary Tree</span>
                <div className={"formWrapper"}>
                    <form onSubmit={sendCreateNewTreeRequest}>
                        <div className={"createTreeInputWrapper"}>
                            <label className={"xlCaption"}>{"Set title of new form: "}</label>
                            <input
                                className={"inputStyle"}
                                type={"text"}
                                value={title}
                                onChange={handleInputChange}
                                data-testid={"newTreeNameTestId"}
                            />
                        </div>
                        <div className={"buttonWrapper"}>
                            <button className={"buttonStyle"} type={"submit"}>
                                <span>Create Tree</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateTree;
