import React from 'react';
import TopNavigation from "components/top-navigation/TopNavigation";
import {useLocation} from "react-router-dom";
import {BinaryTreeNodeDTOInterface } from "../../../data/interfaces";
import BinaryTree from "../single-tree/binary-tree/BinaryTree";
import {getBinaryTree} from "data/binaryTreesRquests";
import SidebarAddNode from "./sidebar-add-node/SidebarAddNode";

function EditTree() {
    // data related states:
    const [treeIdState, setTreeIdState] = React.useState<number>(-1);
    const [titleState, setTitleState] = React.useState<string>("");
    const [userIdState, setUserIdState] = React.useState<number>(-1);

    const [tree, setTree] = React.useState<BinaryTreeNodeDTOInterface | null>(null);
    const [nextNodePosition, setNextNodePosition] = React.useState<string>("C");
    const [parentNodeId, setParentNodeId] = React.useState<string>("-1");
    const [currentNodeLevel, setCurrentNodeLevel] = React.useState<string>("0");

    // rerender and lifecycle related states:
    const [openSidebar, setOpenSidebar] = React.useState<boolean>(false);
    const [rerenderNode, setRerenderNode] = React.useState<boolean>(false);
    const [rerender, setRerender] = React.useState<boolean>(false);
    const [rerenderForm, setRerenderForm] = React.useState<boolean>(false);

    // passed in parameters during navigate to EditTree page.
    const tree_id = useLocation().state.tree_id;
    const title = useLocation().state.title;
    const user_id = useLocation().state.user_id;

    React.useEffect(()=> {
        setTreeIdState(tree_id);
        setTitleState(title);
        setUserIdState(user_id);
        setRerender(!rerender);
    }, []);

    /*
    * Rerender component after states have been updated from a single node, to add new node sidebar updated params:
    * */
    React.useEffect(()=>{
        setRerenderForm(prevState => !prevState);
    }, [nextNodePosition, parentNodeId])

    /*
    * This function is called every time when rerender variable is changed:
    * Change is triggered from SidebarAddNode component after root node was created.
    * This allows to fetch the new tree data that includes the root node.
    * */
    React.useEffect(() => {
        if (treeIdState > 0) {
            fetchBinaryTreeData()
        }
    }, [rerender]);

    /*
    * fetches tree after new tree already has value in binary_trees and its root node in tree_nodes in database.
    * */
    const fetchBinaryTreeData = async () => {
        const newTree = await getBinaryTree(Number(treeIdState));
        if (newTree?.node_id) {
            setTree(newTree);
            // rerender nodes after fetched tree was set
            setRerenderNode(prevState => !prevState);
        }
    }

    return(
        <div className={"pageWrapper"}>
            <TopNavigation />
            <SidebarAddNode
                isOpen={openSidebar}
                toggleSidebarOpenCallback={setOpenSidebar}
                rerenderCallback={setRerender}
                treeId={tree_id}
                nodePosition={nextNodePosition}
                rerenderForm={rerenderForm}
                parentId={parentNodeId}
                currentNodeLevel={currentNodeLevel}
            />
            <div className={"pageContent"}>
                <span className={"header"} >Edit nodes in Binary Tree</span>
                <div className={"containerContent"}>
                    <div className={"userTopSection"}>
                        <div className={"userTopCaption"}>
                            <span className={"caption"}>{"Title: "}</span>
                            <span className={"mainCaption"}>{titleState}</span>
                        </div>
                        <div className={"userTopCaption"}>
                            <span className={"caption"}>{"ID of tree: "}</span>
                            <span className={"mainCaption"}>{tree_id}</span>
                        </div>
                        <div className={"userTopCaption"}>
                            <span className={"caption"}>{"Author ID: "}</span>
                            <span className={"mainCaption"}>{userIdState}</span>
                        </div>
                    </div>
                    <div className={"treeCreationWrapper"}>
                        {tree == null ?
                            <div className={"buttonWrapper"}>
                                <span>Create Root Node:</span>
                                <button
                                    className={"buttonStyle"}
                                    onClick={() => setOpenSidebar(true)}
                                    data-testid={"createRootNodeButtonTestId"}
                                >Create Node</button>
                            </div>
                            :
                            <BinaryTree
                                rootNode={tree}
                                edit={true}
                                rerenderCallback={setRerender}
                                rerender={rerenderNode}
                                openCreateNodeSidebarCallback={setOpenSidebar}
                                setNextNodePosCallback={setNextNodePosition}
                                setParentNodeIdCallback={setParentNodeId}
                                setCurrentNodeLevelCallback={setCurrentNodeLevel}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditTree;
