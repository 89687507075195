import React from 'react';
import {BinaryTreeNodeDTOInterface, BinaryTreeNodeInterface} from "data/interfaces";
import TreeNode from "./tree-node/TreeNode";
import './BinaryTree.css';
import SidebarEditNode from "./sidebar-edit-node/SidebarEditNode";

/*
* Function that recursively builds nodes structure that includes additional frontend related attributes that are needed for displaying the structure on page:
* */
function assignRootNodeAttributes(node: BinaryTreeNodeDTOInterface | null, level: number, position: string, path: string[]): BinaryTreeNodeDTOInterface | null {
    if (node == null) return null;

    switch(position) {
        case "root":
            node.path = ["C"];
            break;
        case "left":
            node.path = [...path, "L"];
            break;
        case "right":
            node.path = [...path, "R"];
            break;
        default:
            node.path = [];
            break;
    }

    node.nodeLevel = level;
    node.nodePosition = position;
    assignRootNodeAttributes(node.left_node, level+1, "left", node.path);
    assignRootNodeAttributes(node.right_node, level+1, "right", node.path);
    return node;
}

interface BinaryTreeProps {
    rootNode: BinaryTreeNodeDTOInterface | null;
    edit: boolean
    rerenderCallback: React.Dispatch<React.SetStateAction<boolean>> | null,
    rerender: boolean,
    openCreateNodeSidebarCallback?: React.Dispatch<React.SetStateAction<boolean>>,
    setNextNodePosCallback?: React.Dispatch<React.SetStateAction<string>>,
    setParentNodeIdCallback?: React.Dispatch<React.SetStateAction<string>>,
    setCurrentNodeLevelCallback?: React.Dispatch<React.SetStateAction<string>>,
}

const currentNodePlaceholder: BinaryTreeNodeInterface = {
    id: -1,
    tree_id: -1,
    parent_id: -1,
    left_child_id: -1,
    right_child_id: -1,
    caption: "",
    position: "",
    node_level: "",
}
const nodePlaceholder: BinaryTreeNodeDTOInterface = {
    node_id: -1,
    current_node: currentNodePlaceholder,
    left_node: null,
    right_node: null,
}

function BinaryTree({
                        rootNode,
                        edit,
                        rerenderCallback,
                        rerender,
                        openCreateNodeSidebarCallback,
                        setNextNodePosCallback,
                        setParentNodeIdCallback,
                        setCurrentNodeLevelCallback
}: BinaryTreeProps ) {
    const [treeWithFEAttributes, setTreeWithFEAttributes] = React.useState<BinaryTreeNodeDTOInterface | null>(null);

    // states used by node component to render single sidebar in proper position:
    const [openSidebar, setOpenSidebar] = React.useState<boolean>(false);
    const [nodeForSidebar, setNodeForSidebar] = React.useState<BinaryTreeNodeDTOInterface>(nodePlaceholder);
    const [rerenderBinaryTree, setRerenderBinaryTree] = React.useState<boolean>(false);

    React.useEffect(()=> {}, [rerenderBinaryTree]);

    React.useEffect(()=> {
        setTreeWithFEAttributes(assignRootNodeAttributes(rootNode, 0, "root", []));
    }, [rerender]);

    /*
    * On initialize, set the extra attributes to default values representing center root node of the binary tree:
    * */
    React.useEffect(() => {
        setTreeWithFEAttributes(assignRootNodeAttributes(rootNode, 0, "root", []));
    }, [treeWithFEAttributes])

    /*
    * Checking which class name should be given to currently iterated tree level brach
    * Each class represents lesser width than the above class, applied to display each level properly on page.
    * */
    const getTreeLevelClass = (nodeLevel: number): string => {
        [0,1,2].includes(nodeLevel)

        switch (nodeLevel) {
            case 0 || 1:
                return "topLevel";
                break;
            case 2:
                return "secondLevel";
                break;
            case 3:
                return "thirdLevel";
                break;
            default:
                return "";
        }
    }

      const renderNode = (node: BinaryTreeNodeDTOInterface) => {
        if(!node) return null;

        // do not display nodes below level 3:
        // if(node.nodeLevel && node.nodeLevel > 3 ) return null;

        let treeLevelClass = "-1";

        if (node.nodeLevel) {
            treeLevelClass = getTreeLevelClass(node.nodeLevel);
        }

        return (
            <div className={`binaryTreeWrapper ${treeLevelClass}`}>
                <TreeNode
                    node={node}
                    renderNodeRecursivelyCallback={renderNode}
                    edit={edit}
                    rerenderFromTree={rerender}
                    openSidebarCallback={setOpenSidebar}
                    setNodeForSidebarCallback={setNodeForSidebar}
                    openCreateNodeSidebarCallback={openCreateNodeSidebarCallback || null}
                    setNextNodePosCallback={setNextNodePosCallback || null}
                    setParentNodeIdCallback={setParentNodeIdCallback || null}
                    setCurrentNodeLevelCallback={setCurrentNodeLevelCallback || null}
                    rerenderCallback={rerenderCallback}
                />
            </div>
        );
    }

    return (
        <>
            {edit &&
                <SidebarEditNode
                    isOpen={openSidebar}
                    toggleSidebarOpenCallback={setOpenSidebar}
                    rerenderCallback={rerenderCallback}
                    rerenderBinaryTreeCallback={setRerenderBinaryTree}
                    treeId={nodeForSidebar.current_node.tree_id?.toString()}
                    nodePosition={nodeForSidebar.current_node.position}
                    nodeCaption={nodeForSidebar.current_node.caption}
                    parentId={nodeForSidebar.current_node.parent_id?.toString()}
                    nodeId={nodeForSidebar.current_node.id?.toString()}
                />
            }
            {
                (treeWithFEAttributes !== null) ?
                    renderNode(treeWithFEAttributes)
                    :
                    (<div>
                        <span>No Binary Tree to display...</span>
                    </div>)
            }
        </>
    )
}

export default BinaryTree;
