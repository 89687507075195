import React from 'react';
import "./Arrow.css";

interface ArrowProps {
    direction: string;
    isSingleRightArrow?: boolean
}

function Arrow({direction, isSingleRightArrow}: ArrowProps) {
    return(<div
            className={`${(isSingleRightArrow) ? "noArrowPlaceholder" : ""}`}
            data-testid={"noArrowPlaceholderTestId"}
    >
        <div className={"arrowWrapper"}>
        {(direction === 'left') && (<>
            <div className={"arrowLine arrowLineLeft"} data-testid={"arrowLeftLineTestId"}></div>
            <div className={"arrowHead arrowHeadLeft"} data-testid={"arrowLeftHeadTestId"}></div>
        </>)}
        {(direction === 'right') && (<>
            <div className={"arrowLine arrowLineRight"} data-testid={"arrowRightLineTestId"}></div>
            <div className={"arrowHead arrowHeadRight"} data-testid={"arrowRightHeadTestId"}></div>
        </>)}
        </div>
    </div>);
}

export default Arrow;
