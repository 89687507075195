import { endPointUrlLocal } from "./staticData";

const endPointUrl = endPointUrlLocal;

interface LoginResponse {
    status: string,
    token: string,
    username: string,
    role_id: string,
    user_id: string,
    profile_picture_url: string
}

interface LogoutResponse {
    token: string,
    role_id: string,
    message: string,
    status: string
}

interface RegisterResponse {
    message: string
    status: string
}

/*
* Sends register request by authenticated admin user
* */
export async function registerUserAuth(
    username: string,
    password: string,
    email: string,
    firstName: string,
    lastName: string,
    isAdmin: string
) {
    const apiUrl = endPointUrl + '/api/public/register-auth';
    const token = localStorage.getItem("token");

    if (token && token.length > 0) {

        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "AUTHORIZATION": token
                },
                body: JSON.stringify({
                    username,
                    password,
                    email,
                    firstName,
                    lastName,
                    isAdmin
                }),
            });

            if (!response.ok) {
                throw new Error("Request POST /api/public/register failed with status: " + response.status);
            }

            const responseJson: RegisterResponse = await response.json();
            return responseJson;
        } catch (error) {
            console.error("Error attempting to perform request POST: /api/public/register");
        }
    }
}

/*
* Sends register request by new not authenticated user
* */
export async function registerUser(
    username: string,
    password: string,
    email: string,
    firstName: string,
    lastName: string,
    isAdmin: string
) {
    const apiUrl = endPointUrl + '/api/public/register';

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username,
                password,
                email,
                firstName,
                lastName,
                isAdmin
            }),
        });

        if (!response.ok) {
            throw new Error("Request POST /api/public/register failed with status: " + response.status);
        }

        const responseJson: RegisterResponse = await response.json();
        return responseJson;
    } catch (error) {
        console.error("Error attempting to perform request POST: /api/public/register");
    }
}

/*
* Sends login request, on successful response, sets authentication JWT token in local storage
* */
export async function loginUser(username: string, password: string) {
    const apiUrl = endPointUrl + '/api/public/login';

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username,
                password
            }),
        });

        if (!response.ok) {
            throw new Error("Request POST /api/public/login failed with status: " + response.status);
        }

        const responseJson: LoginResponse = await response.json();

        /*
        * Store token in local storage after successful response.
        * */
        localStorage.setItem('token', responseJson.token);
        return responseJson;
    } catch (error) {
        console.error("Error attempting to perform request POST: /api/public/login");
    }
}

/*
* Sends logout request.
* */
export async function logoutUserRequest() {
    const apiUrl = endPointUrl + '/api/public/logout';

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            }
        });

        if(!response.ok) {
            console.error("Logout request failed.");
        }

        const responseJson: LogoutResponse = await response.json();
        localStorage.setItem("token", responseJson.token);
        return responseJson;
    } catch (error) {
        console.error("Error attempting to perform request POST: /api/public/logout");
    }

}

/*
* Validate if current token is still valid, verifies if user is still logged in
* returns the user type, if user is regular user, or admin user:
* */
export async function getUserType() {
    const apiUrl = endPointUrl + '/api/public/get-user-type';
    const token = localStorage.getItem("token");

    if (token && token.length > 0) {
        try {
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Content-Type" : "application.json",
                    "AUTHORIZATION": token
                },
            });

            if (!response.ok) {
                throw new Error("Request POST /api/public/get-user-type failed. Response: " + response);
            }

            const resp: any = await response.json();
            return resp;
        } catch(error) {
            console.error("Error attempting to perform request GET: /api/public/get-user-type")
        }
    } else {
        console.error("there is no token.");
    }
}

export async function getUserProfileDetails(user_id: string) {
    const apiUrl = endPointUrl + '/api/public/user-details/' + user_id;
    const token = localStorage.getItem("token");

    if (token && token.length > 0) {
        try {
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Content-Type" : "application.json",
                    "AUTHORIZATION": token
                },
            });

            if (!response.ok) {
                throw new Error("Request POST /api/public/user-details failed. Response: " + response);
            }

            const resp: any = await response.json();
            return resp;
        } catch(error) {
            console.error("Error attempting to perform request GET: /api/public/user-details")
        }
    } else {
        console.error("there is no token.");
    }
}


