import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ListOfTrees from "./components/pages/list-of-trees/ListOfTrees";
import Login from "./components/pages/login/Login";
import SingleTree from "./components/pages/single-tree/SingleTree";
import {UserProvider} from "./helpers/context/ContextApi";
import Profile from "./components/pages/profile/Profile";
import CreateTree from "./components/pages/create-tree/CreateTree";
import EditTree from "./components/pages/edit-tree/EditTree";
import Register from "./components/pages/register/Register";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />
    },
    {
        path: "/list",
        element: <ListOfTrees />
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/tree",
        element: <SingleTree />
    },
    {
        path: "/profile",
        element: <Profile />
    },
    {
        path: "/create_tree",
        element: <CreateTree />
    },
    {
        path: "/edit_tree",
        element: <EditTree />
    },
    {
        path: "/register",
        element: <Register />
    },
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
