import React, { createContext, useReducer, useContext } from "react";
import {User} from 'data/interfaces';
import {getUserType } from "data/userRequests";

interface UserState {
    user: User | null;
}

type Action = { type: "LOGIN"; payload: User } | { type: "LOGOUT";};

const UserContext = createContext<{state: UserState; dispatch: React.Dispatch<Action>}| undefined>(undefined)

/*
* Define reducer function
* */

const userReducer = (state: UserState, action: Action): UserState => {
    switch (action.type) {
        case "LOGIN":
            return {
                user: action.payload
            };
            break;
        case "LOGOUT":
            return {
                user: null
            };
            break;
        default:
            return state;
    }
}

/*
* User provider function that uses the userReducer.
* */
export function UserProvider({children}: {children: React.ReactNode}) {
    const [state, dispatch] = useReducer(userReducer, {user: null});

    /*
    * Empty dependency array, prevents function to trigger this request on loop.
    * */
    React.useEffect(() => {
        getUserTypeFromServer();
    }, []);

    const getUserTypeFromServer = async () => {
        const response = await getUserType();

        if(response) {
            if (response.role_id) {
                dispatch({
                    type: "LOGIN",
                    payload: {
                        role_id: response.role_id,
                    }
                });
                return response;
            }
        }
    }

    return (
        <UserContext.Provider value={{state, dispatch}}>
            {children}
        </UserContext.Provider>
    );
}

/*
* function to access user from context:
* */

export const useUser = () => {
    const context = useContext(UserContext);
    if (context != undefined) {
        return context;
    }
}

export default UserContext;
