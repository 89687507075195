import React, {useContext} from 'react';
import "./TopNavigation.css";
import { Link } from "react-router-dom";
import UserContext from "helpers/context/ContextApi";

interface ButtonsInterface {
    route: string,
    caption: string,
    public: boolean
}

const buttons: ButtonsInterface[] = [
    {route: "/list", caption: "List of Trees", public: false},
    {route: "/create_tree", caption: "Create New Tree", public: false},
    {route: "/login", caption: "Login", public: true},
    {route: "/profile", caption: "Profile Page", public: false},
    {route: "/register", caption: "Register", public: true},
];

function TopNavigation() {
    const contextValue = useContext(UserContext);
    const [userRole, setUserRole] = React.useState<String>("")
    const [roleUpdated, setRoleUpdated] = React.useState<boolean>(false);
    const [isLeftSideMenuVisible, setIsLeftSideMenuVisible] = React.useState<boolean>(false);
    const [hamburgerImage, setHamburgerImage] = React.useState<string>("/hamburgerImg.png");

    /*
    * checks if button or section in navigation tab can be displayed.
    * if button is not available for logged-out users, then we check if user is logged in to display it.
    */
    const canDisplayButton = (isPublic: boolean, caption: string): boolean => {
        if (!isPublic) {
            return isUserLoggedIn();
        }

        // display login button only if user is logged out
        if (caption === "Login") {
            if (isUserLoggedIn()) return false;
        }

        // display register button only if user is logged out, or if user is logged in admin.
        if (caption === "Register") {
            if (isUserLoggedIn()) {
                if (contextValue && contextValue.state && contextValue.state.user && contextValue.state.user.role_id) {
                    if (contextValue.state.user.role_id == "1") return true;
                    if (contextValue.state.user.role_id == "2") return false;
                }
            }
        }
        return isPublic;
    }

    /*
    * Rerender component if role type was updated:
    * */
    React.useEffect(()=>{}, [roleUpdated]);

    /*
    * Set role id whenever context value of user role has changed:
    * */
    React.useEffect(() => {
        if (contextValue && contextValue.state && contextValue.state.user && contextValue.state.user.role_id) {
            setUserRole(contextValue.state.user.role_id);
            setRoleUpdated(prevState => !prevState);
        }
    }, [contextValue])

    /*
    * user is logged in if user role is 1 or 2.
    * 1 means admin,
    * 2 means regular user
    * */
    const isUserLoggedIn = (): boolean => {
        return (userRole == "1" || userRole == "2") ? true : false;
    }

    /*
    * opens and closes left side menu that replaces top-navigation menu on small screen device:
    * */

    const handleLeftSideMenu = ()=> {
        setIsLeftSideMenuVisible(prevState => !prevState);
    }

    const renderSideMenu = () => {
        return (<div className={(isLeftSideMenuVisible === true) ? "sideNavigation sideNavigationOpen" : "sideNavigation"}>
            {buttons && buttons.map((button: ButtonsInterface, id: number) => {
            if (canDisplayButton(button.public, button.caption)) {
                return <Link className={"sideNavButtonWrapper"} to={button.route} key={id} data-testid={`${button.caption.toLowerCase()}SideNavTestId`}>
                    <div className={"topNavButtonContent"}>
                        <span className={"topNavCaption"}>{button.caption}</span>
                    </div>
                </Link>
            }
            return <div key={id}></div>
        })}
        </div>);
    }

    return(<>
        {isLeftSideMenuVisible && renderSideMenu()}
        <div className={"topNavWrapper"}>
                <div className={"hamburgerContainer topNavButtonWrapper"}>
                    <div className={"topNavButtonContent"} onClick={handleLeftSideMenu}>
                        <img className={"topNavCaption"} src={hamburgerImage} alt={"menu"}/>
                    </div>
                </div>
                {buttons && buttons.map((button: ButtonsInterface, id: number) => {
                    if (canDisplayButton(button.public, button.caption)) {
                        return <Link className={"topNavButtonWrapper"} to={button.route} key={id} data-testid={`${button.caption.toLowerCase()}NavTestId`}>
                            <div className={"topNavButtonContent"}>
                                <span className={"topNavCaption"}>{button.caption}</span>
                            </div>
                        </Link>
                    }
                    return <div key={id}></div>
                })}
        </div>
    </>);
}

export default TopNavigation;
